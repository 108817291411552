import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const DynamicTable = ({info, columns}) => {

  // {info}
  console.log("info::", info);
  console.log("columns::", columns);
  return (
    <>
      <Row>
        <Col md={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr style={{backgroundColor:'#888888'}}>
                {columns.map((column) => (
                  <th key={column.element_name} style={{color:'#FFF'}} >{column.display_name}</th>
                ))}               
              </tr>
            </thead>
            <tbody> 
              {info.map((item, index) => {    
                const rowColor = index % 2 === 0 ? 'even-row' : 'odd-row'; // Define row color
 
                return (           
                <tr key={index} className={rowColor} style={{ backgroundColor: index % 2 !== 0 ? '' : '#efeff8' }} >                  
                  {columns.map((column) => (
                    <td >{item[column.element_name]}</td> 
                  ))}
                </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default DynamicTable;
