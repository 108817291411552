import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const GeneralInfo = ({info, additional_info}) => {
  let navigate = useNavigate();
  // {info}
  console.log("info::", info);
  console.log("info::", additional_info);
  //{"store_sap_name":{"filterType":"text","type":"Contains","filter":"497D"},"emp_status":{"filterType":"text","type":"contains","filter":"Active"}}
  //href= '/employees?filter={"store_sap_name":{"filterType":"text","type":"Contains","filter":${info?.sap}},"emp_status":{"filterType":"text","type":"contains","filter":"Active"}}' 
  return (
    <>
      <Row>
        <Col>
          <dl className="row">
            <dt className="col-sm-4"> SAP </dt>
            <dd className="col-sm-8"> <a href={`/stores/${info?.id}`} target="_blank"  >  {info?.sap} </a> </dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4"> Store Name </dt>
            <dd className="col-sm-8"> <a href={`/employees?filter={"store_sap_name":{"filterType":"text","type":"Contains","filter":"${info?.sap}"},"emp_status":{"filterType":"text","type":"contains","filter":"Active"}}`} target="_blank"  >  {info?.store_name} </a> </dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Address</dt>
            <dd className="col-sm-8">{info?.address}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Acquisition Dealer</dt>
            <dd className="col-sm-8">{info?.acquisition_dealer_name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Store Classification</dt>
            <dd className="col-sm-8">{info?.store_classification_name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Phone</dt>
            <dd className="col-sm-8"> <a href= {info?.store_phone ? `tel:${info.store_phone.replace(/[^\d]/g, '')}` : undefined}>  {info?.store_phone} </a>  </dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Store Hours</dt>
            <dd className="col-sm-8">{info?.store_hrours}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Supported By</dt>
            <dd className="col-sm-8">{info?.supported_by}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Camera System</dt>
            <dd className="col-sm-8">{additional_info?.data?.[0]?.Camera_System}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Network Support</dt>
            <dd className="col-sm-8">{additional_info?.data?.[0]?.Network_Support}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Phone Support</dt>
            <dd className="col-sm-8">{additional_info?.data?.[0]?.Phone_Support}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Printer Support</dt>
            <dd className="col-sm-8">{additional_info?.data?.[0]?.Printer_Support}</dd>
          </dl>
          {/* <dl className="row">
            <dt className="col-sm-4">Current Location Time</dt>
            <dd className="col-sm-8">{info[0]?.store_name}</dd>
          </dl> */}
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col md={8}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Title</th>                
                <th>First Name</th>
                <th>Last Name</th>
                <th>Work Phone</th>
                <th>Personal Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>RSM</td>                
                <td>{info?.rsm.first_name}</td>
                <td>{info?.rsm.last_name}</td>
                <td>{info?.rsm.Phone}</td>
                <td>{info?.rsm.personal_phone}</td>
                <td> <a href= {`mailto:${info?.rsm.Email}`}>  {info?.rsm.Email} </a></td>                
              </tr>
              <tr>
                <td>DSOM</td>                
                <td>{info?.dsom.first_name}</td>
                <td>{info?.dsom.last_name}</td>
                <td>  <a href={info?.dsom.Phone ? `tel:${info.dsom.Phone.replace(/[^\d]/g, '')}` : undefined}>  {info?.dsom.Phone} </a> </td>
                <td>{info?.dsom.personal_phone}  </td>
                <td> <a href= {`mailto:${info?.dsom.Email}`}>  {info?.dsom.Email} </a></td>
              </tr>
              <tr>
                <td>DOSO</td>              
                <td>{info?.doso.first_name}</td>
                <td>{info?.doso.last_name}</td>
                <td> <a href={info?.doso.Phone ? `tel:${info.doso.Phone.replace(/[^\d]/g, '')}` : undefined}>  {info?.doso.Phone} </a>  </td> 
                <td>{info?.dsom.personal_phone}  </td>
                <td> <a href= {`mailto:${info?.doso.Email}`}>  {info?.dsom.Email} </a> </td>                
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default GeneralInfo;
